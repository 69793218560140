import React, { useEffect, useState} from 'react';
import {useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button1 from '../components/Button1';
import "./Home.css";

//const logo = require('../images/logo.svg');
// this is comment to see progress
import logo from '../images/logo.svg';
import hero_ill from '../images/hero-ill.png';
import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';

import play_and_learn from '../images/play_and_learn.png';
import leaderboard from '../images/leaderboard.png';
import community from '../images/community.png';
import NFTs from '../images/NFT.png';

import even1 from '../images/even1.png';
import even2 from '../images/even2.png';
import even3 from '../images/even3.png';
import arrow from '../images/arrow.svg';

import about from '../images/about.png';
//import aboutbot from '../images/aboutbot.png';
import mail from '../images/mail.svg';

import telegram from '../images/telegram.svg';
import discord from '../images/discord.svg';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';
import ln from '../images/ln.svg';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react'

const Home: React.FC = () => {
    const [slideIndex, setSlideIndex] = useState(1);
    const [textSlideIndex, setTextSlideIndex] = useState(1);
    const navigate = useNavigate();
    let interval: NodeJS.Timeout;
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {

        showSlides(slideIndex);
        showSlides(textSlideIndex);
        interval = setInterval(() => plusSlides(1), 6000);

        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
    
        // Initial check
        updateIsMobile();
    
        // Event listener for window resize
        window.addEventListener('resize', updateIsMobile);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', updateIsMobile);
        };

    }, []);


    function handleClickRegister(){
        navigate("/register");
    }

    function handleClickLogin() {
        navigate("/login");
    }

    function plusSlides(n: number) {
        showSlides(slideIndex + n);
    }

    function currentSlide(n: number) {
        showSlides(n);
        clearInterval(interval);
        interval = setInterval(() => plusSlides(1), 6000);
    }

    function showSlides(n: number) {
        let i;
        let slides = document.getElementsByClassName('sw-image') as HTMLCollectionOf<HTMLElement>;    // for the typescript
        let textSlides = document.getElementsByClassName('sw-content') as HTMLCollectionOf<HTMLElement>;
        let dots = document.querySelectorAll('.sw-item .number');
        let dotsMobile = document.querySelectorAll('.sw-pagination .number');

        if (n > slides.length) {
            setSlideIndex(1);
        } else if (n < 1) {
            setSlideIndex(slides.length);
        } else {
            setSlideIndex(n);
        }

        if (n > textSlides.length) {
            setTextSlideIndex(1);
        } else if (n < 1) {
            setTextSlideIndex(textSlides.length);
        } else {
            setTextSlideIndex(n);
        }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
            textSlides[i].style.display = 'none';
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(' active', '');
            dotsMobile[i].className = dots[i].className.replace(' active', '');
        }

        slides[slideIndex - 1].style.display = 'block';
        textSlides[slideIndex - 1].style.display = 'block';
        dots[slideIndex - 1].className += ' active';
        dotsMobile[slideIndex - 1].className += ' active';
    }
    //<div id="preloader"></div>
    const [openFAQ, setOpenFAQ] = useState(null);

    // Function to toggle FAQ item
    const toggleFAQ = (index: any) => {
        if (openFAQ === index) {
            setOpenFAQ(null); // Close if the same item is clicked
        } else {
            setOpenFAQ(index); // Open the clicked item
        }
    };

    return (
    <div>
        

        <div className="wrapper">
            <div className="content">
                <header className="header">
                {/*    <div className="announcement">
                        <p style={{color: "black"}} className="large">
                            Download the app now and join our trading competition!
                            <a href="/competitionrules" style={{color: "white"}} className="large"> Read the rules</a>
                        </p>
                        </div> */}

                    <div className="header-wrapper">
                        <a href="/" className="logo">
                            <img src={logo}  alt=""/>
                        </a>
                        <Button1 name="Business" url="business"/>
                        {/*isMobile ? (
                                // Render the dropdown menu for mobile screens
                                <div id="hamburger-menu">
                                   <Menu>
                                    <MenuButton as={Button} className="button btn-primary">
                                    Actions
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/business")}}>Business</MenuItem>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/login")}}>Login</MenuItem>}
                                    </MenuList>
                                    </Menu>
                                </div>
                            ) : (
                                // Render the buttons for larger screens
                                <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                    <Button1 name="Business" url="business"/>
                                    <Button1 name="Login" url="login"/>
                                </div>
                            )*/}
                    </div>
                </header>
                <header className="header sticky">
                    <div className="header-wrapper">
                        <a href="/" className="logo">
                            <img src={logo} alt=""/>
                        </a>
                        {/*<a href="/business"><button className="button btn-primary">For Business</button></a>*/}
                        <Button1 name="Business" url="business"/>
                        <Button1 name="Login" url="login"/>
                    </div>
                </header>

                <section className="hero">
                    <div className="container" style={{marginTop: "50px"}}>
                        <div className="hero-wrapper">
                            <div className="hero-info">
                                <h1 className="h1 green-gradient" /*data-aos="fade-up"*/ /*data-aos-delay="500"*/>Financial education. Gamified.<br /></h1>
                                <p className="large" /*data-aos="fade-up"*/ /*data-aos-delay="550"*/>Transforming financial education into a gamified journey.</p>
                                <div className="hero-response"></div>
                                <h5 className="green" /*data-aos="fade-up"*/ /*data-aos-delay="600"*/>Download the mobile app now!</h5>

                                <style>
                                {`
                                .storeLink {
                                    position: relative;
                                    display: inline-block;
                                    width: 240px;
                                    height: 80px;
                                    border-radius: 16px;
                                    overflow: hidden;
                                    background-color: black;
                                }
                                .storeLink > img {
                                    --width: 100%;
                                    position: absolute;
                                    width: var(--width);
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                                `}
                                </style>

                                <a href="https://apps.apple.com/tr/app/swollet/id1641853404" className="storeLink"><img src="https://pngate.com/wp-content/uploads/2023/11/Download-on-App-Store-Classic-Black-Button.png" alt="Download on the App Store" /></a>
                                <a href="" className="storeLink"><img alt='Get it on Google Play' src="https://www.aps.edu/students-parents/images/downloadOnTheGooglePlayStoreBadge.png" /></a>

                            </div>
                            <div className="hero-ill" /*data-aos="fade-up"*/ /*data-aos-delay="500"*/>
                                <img src={hero_ill} alt=""/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="spending">
                    <div className="container">
                        <div className="section-header" /*data-aos="fade-up"*/>
                            <h2 className="h2 green-gradient">We make learning about investing fun and rewarding.</h2>
                        
                            <p className="large"></p>
                        </div>
                        <div className="spending-wrapper">
                            <div className="item" /*data-aos="fade-up"*/>
                                <img src={img1} alt=""/>
                                <div className="item-content">
                                    <h5>Play and learn</h5>
                                    <p>Play and learn about financial literacy, work readiness, entrepreneurship through bite-sized courses. Content is created by top notch academic institutions.</p>
                                </div>
                            </div>
                            <div className="item" /*data-aos="fade-up" data-aos-delay="100"*/>
                                <img src={img2} alt=""/>
                                <div className="item-content">
                                    <h5>Portfolio Battles</h5>
                                    <p>Test your knowledge against the online community of fellow traders by playing the fantasy trading game. </p>
                                </div>
                            </div>
                            <div className="item" /*data-aos="fade-up" data-aos-delay="200"*/>
                                <img src={img3} alt=""/>
                                <div className="item-content">
                                    <h5>NFT certificates</h5>
                                        <p>Prove your talent on the blockchain by receiving your NFT certificate upon course completion.</p>
                                </div>
                            </div>
                        </div>
                        <div className="spending-slider">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="item">
                                        <img src={img1} alt=""/>
                                        <div className="item-content">
                                        <h5>Play and learn</h5>
                                        <p>Play and learn about financial literacy, work readiness, entrepreneurship through bite-sized courses. Content is created by top notch academic institutions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="item">
                                        <img src={img2} alt=""/>
                                        <div className="item-content">
                                            <h5>Get reward points</h5>
                                            <p>Test your knowledge against the online community of fellow traders by playing the fantasy trading game.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="item">
                                        <img src={img3} alt=""/>
                                        <div className="item-content">
                                            
                                            <h5>NFT certificates</h5>
                                            <p>Prove your talent on the blockchain by receiving your NFT certificate upon course completion.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pagination"></div>
                        </div>
                    </div>
                </section>

                <section className="goals">
                    <div className="container">
                        <div className="section-header" data-aos="fade-up">
                            <h2 className="green-gradient" id="howItWorks">How it works</h2>
                            <p className="large">Our mission is to promote and share investing knowledge among our community, beginning with the empowering step of gamifying education and making it fun.</p>
                        </div>
                        <div className="sw-container">
                            <div className="sw-item sw-item-1" onClick={() => currentSlide(1)}>
                                <p className="number">01</p>
                                <p className="h3 green-gradient">Play and learn</p>
                                <p className="small">Play and learn about financial literacy, work readiness, entrepreneurship through bite-sized courses. Content is created by top notch academic institutions.</p>
                            </div>
                            <div className="sw-item sw-item-2" onClick={() => currentSlide(2)}>
                                <p className="number">02</p>
                                <p className="h3 green-gradient">Battles & PvPs</p>
                                <p className="small">Join our player vs player battles and climb the leaderboard as you improve your financial literacy skills.</p>
                            </div>
                            <div className="sw-item sw-item-3" onClick={() => currentSlide(3)}>
                                <p className="number">03</p>
                                <p className="h3 green-gradient">Join the community and make your voice heard</p>
                                    <p className="small">Create and join communities and share common interests, exchange opinions. Our goal is to create a community which will educate you to take the best financial care of yourself!</p>
                            </div>
                            <div className="sw-item sw-item-4" onClick={() => currentSlide(4)}>
                                <p className="number">04</p>
                                <p className="h3 green-gradient">Prove your talent on-chain</p>
                                    <p className="small">Prove your knowledge on the blockchain by receiving your NFT certificate upon course completion.</p>
                            </div>
                            <div className="sw-images">
                                <img className="sw-image" src={play_and_learn}></img>
                                <img className="sw-image" src={leaderboard}></img>
                                <img className="sw-image" src={community}></img>
                                <img className="sw-image" src={NFTs}></img>
                            </div>
                        </div>
                        <div className="sw-content-container">
                            <div className="sw-content content">
                                <p className="h3 green-gradient">Play and learn</p>
                                <p className="large">Swollet teaches financial literacy, work readiness, entrepreneurship through bite-sized courses. Take courses and earn rewards. How’s that for a new way of learning?</p>
                            </div>
                            <div className="sw-content">
                                <p className="h3 green-gradient">Battles & PvPs</p>
                                <p className="large">Join our player vs player battles and climb the leaderboard as you improve your financial literacy skills.</p>
                            </div>
                            <div className="sw-content">
                                <p className="h3 green-gradient">Join the community and make your voice heard</p>
                                <p className="large">Create and join communities and share common interests, exchange opinions. Our goal is to create a community which will educate you to take the best financial care of yourself!</p>
                            </div>
                            <div className="sw-content">
                                <p className="h3 green-gradient">Convert your knowledge into currency</p>
                                <p className="large">Earn rewards and mint your NFTs to prove your talent on the blockchain.</p>
                            </div>
                        </div>
                        <ul className="sw-pagination">
                            <li className="number" onClick={() => currentSlide(1)}>01</li>
                            <li className="number" onClick={() => currentSlide(2)}>02</li>
                            <li className="number" onClick={() => currentSlide(3)}>03</li>
                            <li className="number" onClick={() => currentSlide(4)}>04</li>
                        </ul>
                    </div>
                </section>

                <section className="even-more">
                    <div className="container" /*data-aos="fade-up"*/>
                        <h3 className="h3 green-gradient">...and even more...</h3>
                    </div>
                    <div className="even-more-wrapper">
                        <div className="item" /*data-aos="fade-up"*/>
                            <img src={even1} alt=""/>
                            <h5>Grow your knowledge</h5>
                            <p>Complete bite-sized course and quizzes to learn about investing.</p>
                        </div>
                        <div className="item" /*data-aos="fade-up"*/ /*data-aos-delay="100"*/>
                            <img src={even2} alt=""/>
                            <h5>Bank-level security</h5>
                            <p>Your peace of mind is our highest priority. We use bank-level security, 256-bit encryption, and allow two-factor authentication for added security.
                            </p>
                        </div>
                        <div className="item" /*data-aos="fade-up"*/ /*data-aos-delay="200"*/>
                            <img src={even3} alt=""/>
                            <h5>NFT</h5>
                            <p>Swollet will enable users to convert their profile pictures and avatars into NFTs.</p>
                        </div>
                    </div>
                    <div className="even-more-slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="item">
                                    <img src={even1} alt=""/>
                                    <br />
                                    <br />
                                    <h5>Grow your knowledge</h5>
                                    <p>Receive actionable investment news explained in simple terms to help you reach your goals.</p>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="item">
                                    <img src={even1} alt=""/>
                                    <h5>Bank-level security</h5>
                                    <p>Your peace of mind is our highest priority. We use bank-level security, 256-bit encryption, and allow two-factor authentication for added security.</p>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="item">
                                    <img src={even3} alt=""/>
                                    <br />
                                    <br />
                                    <h5>NFT certificate</h5>
                                    <p>Swollet will enable users to prove their talent on the blockchain by minting NFT certificates.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pagination"></div>
                    </div>
                </section>

                <section className="faq">
                    <div className="container">
                        <h2 className="green-gradient">Frequently asked questions</h2>
                        <div className="faq-item" /*data-aos="fade-up"*/>
                            
                            <h4 className="h3" onClick={() => toggleFAQ(1)}>
                                {/*How do I participate in the fantasy trading game?*/}
                                What can I learn on Swollet?
                                <img src={arrow} alt="" />
                            </h4>
                            {openFAQ === 1 && (
                                <div className="">
                                    <p className="small">Swollet offers courses on personal finance, blockchain, cryptocurrencies, artificial intelligence, robotics, quantum computing and more emerging technologies. There's something for everyone!</p>
                                </div>
                            )}
                        </div>

                        <div className="faq-item" /*data-aos="fade-up"*/>
                            <h4 className="h3" onClick={() => toggleFAQ(2)}>
                                {/*Is the game linked to the existing stock/crypto market?*/}
                                Do I need any prior experience?
                                <img src={arrow} alt=""/>
                            </h4>
                            {openFAQ === 2 && (
                            <div className=""/*answer*/>
                                <p className="small">Nope! Swollet courses are designed for complete beginners. No prior knowledge is required.
                                    {/*Swollet is a fantasy gaming application. It is centred towards a single idea, to educate and bring Stocks and Cryptocurrency market enthusiasts from around the world together on a single platform, to
                                    compete against each other in different forms of exciting contests. These contests are based on recent and live Stocks and Cryptocurrency market events, based on informed and tested decision-making skills, which reward
                                    the users with the best portfolio management skills. It also gives the users a standing a chance to win exciting rewards daily, in a fun and engaging, gaming environment. The focus of this platform also transcends
                                    to those who are novice and amateur in the field, by offering them an avenue for training and and development of skill and proficiency, to further test their development by participating in contests provided on the application.
                                    Swollet works on skill based online fantasy gaming principles with respect to the user rewards taking reference based on factual information and historical data from live events, analysis and fluctuations happening in real
                                    time cryptocurrency and Stocks markets.*/}</p>
                            </div>
                            )}
                        </div>


                        <div className="faq-item" /*data-aos="fade-up"*/>
                            <h4 className="h3" onClick={() => toggleFAQ(3)}>Do you manage my money on my behalf?
                                <img src={arrow} alt=""/>
                            </h4>
                            {openFAQ === 3 && (
                            <div className="">
                                <p className="small">Swollet does not manage your money for you and we do not make any discretionary decisions for your money. We just provide you the most innovative platform, tools and setup for you to make the optimal investment decisions.</p>
                            </div>
                            )}
                        </div>

                        <div className="faq-item" /*data-aos="fade-up"*/>
                            <h4 className="h3" onClick={() => toggleFAQ(4)}>
                                Is Swollet only for adults?
                                {/*What are KNOW tokens?*/}
                                <img src={arrow} alt=""/>
                            </h4>
                            {openFAQ === 4 && (
                            <div className="">
                                <p className="small">
                                Swollet is great for learners of all ages! Many of our courses are perfect for teenagers and young adults looking to build their financial literacy.
                                {/*You can redeem your rewards any time by visiting the shop in our mobile app. There are so many benefits to reedem for such as scholarships, free online courses at top tier schools, merch and so on!*/}
                                </p>
                            </div>
                            )}
                        </div>

                        <div className="faq-item" /*data-aos="fade-up"*/>
                            <h4 className="h3" onClick={() => toggleFAQ(5)}>
                                How do I get started with Swollet?
                                <img src={arrow} alt=""/>
                            </h4>
                            {openFAQ === 5 && (
                            <div className="">
                                <p className="small">
                                    Getting started is easy! Just create an account, take our placement quiz to find the ideal starting course, and dive into your first lesson. Learn at your own pace anywhere you want.
                                </p>
                            </div>
                            )}
                        </div>


                    </div>
                </section>

                <section className="contact" /*data-aos="fade-up"*/>
                    <div className="container">
                        <h5>Still have some questions? Reach us out and we will help</h5>
                        <form action="" className="contact-form">
                            <div className="col">
                                <div className="form-group name">
                                    <input type="text" placeholder="Name" />
                                </div>
                                <div className="form-group email">
                                    <input type="email" placeholder="Your e-mail" />
                                </div>
                            </div>
                            <div className="col form-group message">
                                <textarea placeholder="Your message"></textarea>
                            </div>
                            <div className="contact-form-bottom">
                                <p className="description">We provide only relevant information without spamming</p>
                                <button type="submit" className="btn-primary">Send</button>
                            </div>
                        </form>
                    </div>
                </section>

                <section className="about">
                    <div className="container">
                        <h2 className="green-gradient" /*data-aos="fade-up"*/ id="aboutSwollet">About Swollet</h2>
                        <div className="about-wrapper">
                            <div className="left-side">
                                {/*<div data-aos="fade-up">
                                    <h3 className="green-gradient">Who we are</h3>
                                    <p className="large">Swollet! The social learn-to-earn educational platform where anyone can play & learn about finance & crypto while earning our KNOW token. Swollet was founded in 2020 and is headquartered in Dublin, Ireland.</p>
                                </div>*/}
                                <div /*data-aos="fade-up"*/ className="aos-init aos-animate">
                                    <h3 className="green-gradient">Our story</h3>
                                    <br/><p className="large">The idea for Swollet sparked in 2022 when our founders asked, "Why is learning about money and tech still so inaccessible?" They set out to create the most engaging educational platform ever - one that makes finance and emerging tech universally understood.</p>

                                    <br/><p className="large">With expertise in gaming, AI-driven personalization and social networking, our team of educators, designers and engineers brought Swollet to life. We're on a mission to empower people everywhere to take control of their financial futures and understand the technologies shaping our world.</p>

                                    <br/><p className="large">At Swollet, we're obsessed with making learning fun and effective. Our game-inspired courses break down complex concepts into easily digestible bits. Personalized recommendations help you master new material quickly. And our vibrant community of experts and fellow learners ensures you never feel alone.</p>

                                    <br/><p className="large">Whether you're a total beginner or seasoned expert, Swollet has the perfect courses for you. The future belongs to the financially and technologically literate. Together, we can all swol up and own it!</p>
                                </div>
                            </div>
                            <div className="right-side" /*data-aos="fade-up"*/>
                                <img src={about} alt=""/>
                                {/*<img src={aboutbot} className="badge" alt=""/>*/}
                            </div>
                        </div>
                    </div>
                </section>

                <div className="popup-wrapper" id="subscribe" hidden>
                    <div className="popup">
                        <div className="popup-close close">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.1">
                                <path d="M20.4784 18.3612L14.1008 11.9836L20.4618 5.62265C21.0452 5.03928 21.0452 4.08469 20.4618 3.50133C19.8785 2.91796 18.9239 2.91796 18.3405 3.50133L11.9795 9.8623L5.63512 3.5179C5.05175 2.93454 4.09716 2.93453 3.5138 3.5179C2.93043 4.10126 2.93043 5.05586 3.5138 5.63922L9.8582 11.9836L3.49722 18.3446C2.91386 18.928 2.91386 19.8826 3.49722 20.4659C4.08059 21.0493 5.03518 21.0493 5.61854 20.4659L11.9795 14.105L18.357 20.4825C18.9404 21.0659 19.895 21.0659 20.4784 20.4825C21.0617 19.8992 21.0618 18.9445 20.4784 18.3612Z" fill="white"/>
                            </g>
                            </svg>
                        </div>
                        <div className="popup-content">
                            <img src={mail} alt=""/>
                            <p className="green-gradient title">Get early access<br />Join the waitlist to be one of the first testers!</p>
                            <div id="mc_embed_signup">
                                <div className="mailchimp-form">
                                    <form action="https://swollet.us5.list-manage.com/subscribe/post?u=ae47f162bf9213c0ffde5253e&amp;id=c13d1ecd81" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate>
                                        <div id="mc_embed_signup_scroll">
                                            <div className="mc-field-group form-group">
                                                <input type="email" className="required email" placeholder="Your e-mail" name="EMAIL" id="mce-EMAIL" />
                                            </div>
                                            <div id="mce-responses" className="clear form-response">
                                                <div className="response error" id="mce-error-response" style={{display:"none"}}></div>
                                                <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                                            </div>
                                            <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                                <input type="text" name="b_ae47f162bf9213c0ffde5253e_c13d1ecd81" tabIndex={Number("-1")} value="" />
                                            </div>
                                            <div className="clear">
                                                <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="button btn-primary">Get early access</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="success-sent">
                    <p>Your email has been successfully added to the early access list</p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.0875 5.51719L13.1391 15.975C12.8625 16.2937 12.4547 16.5 12 16.5C11.5875 16.5 11.2125 16.3313 10.9406 16.0594L6.44062 11.5547C5.07656 10.2 7.19531 8.07656 8.55938 9.43125L11.9156 12.7922L19.8609 3.50625C19.9641 3.38906 20.0812 3.32344 20.1937 3.24844C18.0469 1.24219 15.1734 0 12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12C24 9.60938 23.2922 7.3875 22.0875 5.51719Z" fill="white"/>
                    </svg>
                </div>
        
        
        
                <footer className="footer">
                    <div className="footer-wrapper clearfix">
                        <div id="footer-left" className="clearfix" style={{padding:"20px", backgroundColor: "#1b1e22", float: "left"}}>
                            <div className="footer-widget widget_text_icl clearfix">
                                <h4 style={{color: "#7EDC6F"}}>Swollet</h4>
                                <div className="textwidget">
                                    <ul className="list-info">
                                        <li><i className="fa fa-map-marker fa-lg"></i><span><a href="/">Home</a></span></li>
                                        <li><i className="fa fa-phone fa-lg"></i><span><a href="#howItWorks">How it works</a></span></li>
                                        <li><i className="fa fa-envelope fa-lg"></i><span><a href="#aboutSwollet">About Swollet</a></span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div id="footer-middle" className="clearfix">
                            <div className="footer-widget widget_nav_menu clearfix" style={{padding:"20px", backgroundColor: "#1b1e22", float: "left"}}>
                                <h4 style={{color: "#7EDC6F"}}>Docs</h4>
                                <div className="menu-footer-menu-%ce%b5%ce%bb%ce%bb%ce%b7%ce%bd%ce%b9%ce%ba%ce%ac-container">
                                    <ul id="menu-footer-menu-%ce%b5%ce%bb%ce%bb%ce%b7%ce%bd%ce%b9%ce%ba%ce%ac" className="menu">
                                        <li id="menu-item-17212" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-17212">
                                            <a href="https://swollet.gitbook.io/swollet/">Documentation</a>
                                        </li>
                                        <li id="menu-item-17214" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-17214">
                                            <a href="/privacypolicy">Privacy Policy</a>
                                        </li>
                                        
                                        <li id="menu-item-17213" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-17213">
                                            <a href="/termsofservice">Terms of Service</a>
                                        </li>
                                        
                                        <li id="menu-item-17213" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-17213">
                                            <a href="/useragreement">User Agreement</a>
                                        </li>

                                        <li id="menu-item-17213" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-17213">
                                            <a href="/competitionrules">Competition Rules</a>
                                        </li>
                                    </ul>
                                </div>
                            </div> 
                        </div>

                        <div id="footer-right" className="clearfix">
                            <div className="footer-widget widget_text_icl clearfix" style={{padding:"20px", backgroundColor: "#1b1e22", float: "left"}}>
                                <h4 style={{color: "#7EDC6F"}}>Socials</h4>
                                <div className="textwidget">
                                    <div className="menu-footer-content widget_nav_menu_findus">
                                        <ul className="footer-menu">
                                            <li>
                                                <a className="SocialC" href="https://t.me/swollet ">Telegram</a>
                                            </li>
                                            <li>
                                                <a className="SocialC" href="https://discord.gg/YwJFgSZ6zE ">Discord</a>
                                            </li>
                                            <li>
                                                <a className="SocialC" href="https://www.instagram.com/teamswollet/ ">Instagram</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>       


                    <a href="#toplink" id="toplink" style={{display: "inline"}}><i className="fa fa-chevron-up fa-2x" aria-hidden="true"></i></a>

                </footer>

            </div>
            <footer className="footer">
                <div className="footer-wrapper">
                    <p className="large">©Swollet. All rights reserved</p>
                    <div className="social">
                        <a href="https://t.me/swollet">
                            <img src={telegram} alt=""/>
                        </a>
                        <a href="https://discord.gg/YwJFgSZ6zE">
                            <img src={discord} alt=""/>
                        </a>
                        <a href="https://www.facebook.com/swolletapp">
                            <img src={facebook} alt=""/>
                        </a>
                        <a href="https://www.instagram.com/teamswollet/">
                            <img src={instagram} alt=""/>
                        </a>
                        <a href="https://www.linkedin.com/company/swollet">
                            <img src={ln} alt=""/>
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    </div>
  )
}

export default Home;